.App {
  text-align: center;
  color: #000;
  font-size: 14px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* custome css for dropdown date */
#dropdown-date {
  display: flex;
  justify-content: center;
  align-items: center;
}

#dropdown-year , #dropdown-month ,#dropdown-day  {
  width: 30%;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 10px;
  
}
#dropdown-year  {
  margin-left: 0;
}

#dropdown-year select, #dropdown-month select, #dropdown-day select{
  height: 39px;
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.date_dropdown-text {
  margin-left: 10px;
  font-weight: normal;
}
